.column {
    position: relative;
    width: 320px;
    margin: 0 15px 10px;
    border-radius: 10px;
    background-color: #ffffff;
    overflow: hidden;
    padding: 5px 10px 15px;
    cursor: pointer;
    transition: all 0.8s ease-in;
    &:hover {
        box-shadow: 6px 6px 10px 1px rgba(0, 0, 0, 0.2);
    }
    &__title {
        cursor: grab;
    }
    &__edit {
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    &__wrapper {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 70vh;
        min-height: 50px;
    }
}
