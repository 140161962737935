.sidebar {
  width: 320px;
  position: fixed;

  height: calc(100vh - 90px - 2rem);
  overflow: auto;
}
a {
  text-decoration: none;
  color: inherit;
}
.activeLink {
  display: block;
  margin: 0 -16px;
  text-decoration: none;
  background: #d8d4d4;
}
