.central {
  padding: 0 1rem 0 1rem;
  flex-grow: 1;
  height: calc(100vh - 90px - 2rem);
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
}
.card {
  flex-shrink: 0;
}
.check {
  width: 2rem;
  height: 2rem;
  border: black 2px solid;
  border-radius: 2px;
}
