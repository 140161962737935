.container {
  margin-top: 1rem;
  display: flex;
}
.header {
  display: flex;
  height: auto;
  padding: 10px;
 // background-color: #e3dfdf;
  border: 4px solid #ffffff;
  align-items: center;
}

@media (max-width: 900px) {
  .header {
    height: 120px;
  }
}

@media (max-width: 600px) {
  .header {
    height: 150px;
  }
}
