.header__weather {
  font-size: 0.9em;
  align-items: end;
  &_icon {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    img {
      width: 100%;
    }
  }
}
.weather__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}